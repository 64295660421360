/* istanbul ignore file */
export default {
  state: {
    okta: {
      // Returned from the oktaClient call
      oktaAuthInstance: {},
      // Returned from initial login using the oktaClient
      authTransaction: {},
      // Results from the authTransaction calls - basically results from oktaAuthInstance calls
      transaction: {},
      code: '',
      enrolled: [],
      factorArray: [],
      factorList: [],
      rememberDevice: false,
      authenticated: false,
      accessToken: '',
      user: {},
    },
  },
  getters: {
    okta({ okta }) {
      return okta;
    },
    oktaAuthClient({ okta: { oktaAuthInstance } }) {
      return oktaAuthInstance;
    },
    oktaAuthTransaction({ okta: { authTransaction } }) {
      return authTransaction;
    },
    oktaTransaction({ okta: { transaction } }) {
      return transaction;
    },
    oktaCode({ okta: { code } }) {
      return code;
    },
    oktaEnrolled({ okta: { enrolled } }) {
      return enrolled;
    },
    oktaFactorArray({ okta: { factorArray } }) {
      return factorArray;
    },
    oktaFactorList({ okta: { factorList } }) {
      return factorList;
    },
    oktaRememberDevice({ okta: { rememberDevice } }) {
      return rememberDevice;
    },
    oktaIsAuthenticated({ okta: { authenticated } }) {
      return authenticated;
    },
    oktaAccessToken({ okta: { accessToken } }) {
      return accessToken;
    },
    oktaUser({ okta: { user } }) {
      return user;
    },
  },
  mutations: {
    updateOkta(state, data) {
      Object.assign(state.okta, data);
    },
    updateOktaAuthClient(state, oktaAuthClient) {
      state.okta.oktaAuthInstance = oktaAuthClient;
    },
    updateOktaAuthTransaction(state, authTransaction) {
      state.okta.authTransaction = authTransaction;
    },
    updateOktaTransaction(state, transaction) {
      state.okta.transaction = transaction;
    },
    updateOktaCode(state, code) {
      state.okta.code = code;
    },
    updateOktaEnrolled(state, enrolled) {
      state.okta.enrolled.push(enrolled);
    },
    clearOktaEnrolled(state) {
      state.okta.enrolled = [];
    },
    setOktaFactorArray(state, factorArray) {
      state.okta.factorArray = factorArray;
    },
    setOktaFactorList(state, factors) {
      if(!factors || (Array.isArray(factors) && factors.length < 1)) {
        state.okta.factorList = [];
        return;
      }
      const hasPush = factors.find((factor) => factor.factorType === 'push' || factor.factor_type === 'push') || false;
      state.okta.factorList = factors.map((factor) => {
        factor.provider = factor.factor_provider ? factor.factor_provider : factor.provider;
        factor.factorType = factor.factor_type ? factor.factor_type : factor.factorType;
        factor.status = factor.status || null;
        if(factor.status === 'PENDING_ACTIVATION') {
          return null;
        }
        if(factor.provider === 'OKTA' && factor.factorType === 'push') {
          return 'OktaPushMfa';
        }
        if(factor.provider === 'OKTA' && factor.factorType === 'token:software:totp') {
          return hasPush ? null : 'OktaMfa';
        }
        if(factor.provider === 'GOOGLE' && factor.factorType === 'token:software:totp') {
          return 'GoogleMfa';
        }
        if(factor.provider === 'OKTA' && factor.factorType === 'sms') {
          return 'SmsMfa';
        }
        if(factor.provider === 'OKTA' && factor.factorType === 'email') {
          return 'EmailMfa';
        }
      })
        .filter(Boolean);
    },
    updateOktaRememberDevice(state, rememberDevice) {
      state.okta.rememberDevice = rememberDevice;
    },
    updateOktaAuthenticated(state, authenticated) {
      state.okta.authenticated = authenticated;
    },
    updateOktaAccessToken(state, accessToken) {
      state.okta.accessToken = accessToken;
    },
    updateOktaUser(state, user) {
      state.okta.user = user;
    },
  },
  actions: {
    updateOkta({ commit }, data) {
      commit('updateOkta', data);
    },
    updateOktaAuthClient({ commit }, data) {
      commit('updateOktaAuthClient', data);
    },
    updateOktaAuthTransaction({ commit }, data) {
      commit('updateOktaAuthTransaction', data);
    },
    updateOktaTransaction({ commit }, data) {
      commit('updateOktaTransaction', data);
    },
    updateOktaCode({ commit }, data) {
      commit('updateOktaCode', data);
    },
    updateOktaEnrolled({ commit }, data) {
      commit('updateOktaEnrolled', data);
    },
    clearOktaEnrolled({ commit }) {
      commit('clearOktaEnrolled');
    },
    setOktaFactorArray({ commit }, data) {
      commit('setOktaFactorArray', data);
    },
    setOktaFactorList({ commit }, data) {
      commit('setOktaFactorList', data);
    },
    updateOktaRememberDevice({ commit }, data) {
      commit('updateOktaRememberDevice', data);
    },
    updateOktaAuthenticated({ commit }, data) {
      commit('updateOktaAuthenticated', data);
    },
    updateOktaAccessToken({ commit }, data) {
      commit('updateOktaAccessToken', data);
    },
    updateOktaUser({ commit }, data) {
      commit('updateOktaUser', data);
    },
  },
};