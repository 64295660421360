<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'CiInvestingApp',
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap');
@import url("https://use.typekit.net/dtt4krx.css");
@import "~@wealthbar/peak-style/scss/index";
@import "scss/navigation";
@import "scss/main";
@import "scss/dashboard";
@import "scss/public";
@import "scss/footer";
</style>
