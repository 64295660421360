import { changeLanguage } from '@wealthbar/i18n';

export function getLocaleCtor(document, window) {
  return function execute() {
    const language = document?.documentElement?.lang
      || window?.navigator?.browserLanguage
      || window?.navigator?.userLanguage
      || window?.navigator?.language
      || '';
    return language.slice(0, 2) === 'fr' ? 'fr-CA' : 'en-CA';
  };
}

export function getLanguageCtor(document, window) {
  return function execute() {
    let currentLanguage = null;
    const param = window?.location?.search?.match(/\blang=([^&#]*)\b/);
    const langParam = !param ? '' : param[1].toLowerCase().slice(0, 2);
    const url = window?.location?.href?.match(/\/ca\/([a-zA-Z0-9-]*)/);
    const langUrl = !url ? '' : url[1].toLowerCase().slice(0, 2);
    const langStorage = window?.localStorage?.getItem('okta-language');

    // langParam overrides langUrl for French
    if(langParam === 'fr') {
      currentLanguage = 'fr-CA';
      // langUrl overrides storage for French
    } else if(langUrl === 'fr') {
      currentLanguage = 'fr-CA';
      // Both langParam and langUrl override storage for English
    } else if(langParam === 'en' || langUrl === 'en') {
      currentLanguage = 'en-CA';
      // Storage overrides browser settings
    } else if(langStorage) {
      currentLanguage = langStorage;
    } else {
      currentLanguage = getLocaleCtor(document, window)();
    }
    window?.localStorage?.setItem('okta-language', currentLanguage);
    return currentLanguage;
  };
}

export function setLanguageCtor(changeLanguage, window) {
  return async function execute(language) {
    if(language.slice(0, 2) === 'fr') {
      window?.localStorage?.setItem('okta-language', 'fr-CA');
      await changeLanguage('fr-CA');
    } else {
      window?.localStorage?.setItem('okta-language', 'en-CA');
      await changeLanguage('en-CA');
    }
  };
}

/* istanbul ignore next */
export default {
  getLocale: getLocaleCtor(document, window),
  getLanguage: getLanguageCtor(document, window),
  setLanguage: setLanguageCtor(changeLanguage, window),
};
