import { OKTA_SETTINGS, OKTA_START_SERVICE } from '@/lib/constants/login';
import { OktaAuth } from '@okta/okta-auth-js';

// Main oktaAuthClient
export async function getOktaAuth(OktaAuthObj = OktaAuth, startService = OKTA_START_SERVICE) {
  const authClient = new OktaAuthObj(OKTA_SETTINGS);
  // authClient.start() breaks in test mode
  /* istanbul ignore next */
  if(startService) {
    try {
      await authClient.start()
    } catch {}
  }
  return authClient;
}

// Signin to okta
export async function oktaAuthLogin(username, password, authClient) {
  try {
    const transaction = await authClient.signInWithCredentials({
      username,
      password,
      options: {
        multiOptionalFactorEnroll: true,
        warnBeforePasswordExpired: true,
      },
    });
    return transaction;
  } catch(err) {
    return Promise.reject(err);
  }
};

// Get access_token and for the callback page
export async function oktaGetWithoutPrompt(sessionToken, authClient) {
  try {
    const result = await authClient.token.getWithoutPrompt({
      responseType: ['token', 'id_token'],
      sessionToken,
      prompt: 'none',
    });
    return result;
  } catch(err) {
    return Promise.reject(err);
  }
};

// Flow for forgot password functionality
export async function oktaForgotPassword(username, factorType = 'SMS', authClient) {
  try {
    const result = await authClient.forgotPassword({
      username,
      factorType,
    });
    return result;
  } catch(err) {
    return Promise.reject(err);
  }
};

// Flow for unlock account functionality
export async function oktaUnlockAccount(username, factorType = 'SMS', authClient) {
  try {
    const result = await authClient.unlockAccount({
      username,
      factorType,
    });
    return result;
  } catch(err) {
    return Promise.reject(err);
  }
};
