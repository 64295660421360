import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';
import { setupOktaAuthenticated } from '@/lib/auth/checkOktaAuthenticated';
import { isiOS } from '@/helpers/utils';

const _ = { setupOktaAuthenticated };

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: to => {
      return { path: '/login' }
    },
  },
  {
    path: '/signup/:partner?',
    name: 'signup',
    component: () => import(/* webpackChunkName: "Signup" */ '../views/signup/SignupView'),
  },
  {
    path: '/signup-email',
    name: 'signupEmail',
    alias: '/login/profile/email',
    component: () => import(/* webpackChunkName: "VerifyEmail" */ '../views/signup/EmailVerificationView'),
  },
  {
    path: '/login/email/:type?',
    name: 'emailToken',
    component: () => import(/* webpackChunkName: "NewPassword" */ '../views/login/EmailTokenView'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "Login" */ '../views/LoginView'),
  },
  {
    path: '/login/callback',
    name: 'callback',
    component: () => import(/* webpackChunkName: "Success" */ '../views/CallbackView'),
  },
  {
    // :service is optional so if it's missing, we get redirected back to the dashboard instead of the login page
    path: '/dashboard/link-account/:service?',
    name: 'linkAccount',
    component: () => import(/* webpackChunkName: "Dashboard" */ '../views/dashboard/LinkAccountView'),
    meta: { requiresAuth: true },
  },
  {
    path: '/dashboard/settings/:page?',
    name: 'settings',
    component: () => import(/* webpackChunkName: "Settings" */ '../views/dashboard/settings/SettingsPageView'),
    meta: { requiresAuth: true },
  },
  {
    path: '/dashboard/settings/basic/email',
    name: 'settingsBasicEmail',
    component: () => import(/* webpackChunkName: "SettingsBasicEmail" */ '../views/dashboard/settings/SettingsEmailVerificationView'),
    meta: { requiresAuth: true },
  },
  {
    path: '/dashboard/settings/verification/:mfa',
    name: 'settingsVerificationMfa',
    component: () => import(/* webpackChunkName: "SettingsVerificationMfa" */ '../views/dashboard/settings/verification/MfaSetupView'),
    meta: { requiresAuth: true },
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import(/* webpackChunkName: "Dashboard" */ '../views/dashboard/DashboardView'),
    meta: { requiresAuth: true },
  },
  {
    path: '/signout',
    name: 'signout',
    component: () => import(/* webpackChunkName: "Signout" */ '../views/SignoutView'),
    meta: { requiresAuth: true },
  },
  {
    path: '/*',
    redirect: to => {
      return { path: '/login' }
    },
  },
];

const routerConfig = {
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
};

if(!isiOS) {
  routerConfig.scrollBehavior = function() { 
    return { x: 0, y: 0, behavior: 'instant' };
  }
}

const router = new VueRouter(routerConfig);

router.beforeEach(async (to, from, next) => {
  try {
    await _.setupOktaAuthenticated()
  } catch {}
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if(!store.getters?.oktaIsAuthenticated) {
      const query = !['/dashboard', '/signout'].includes(to.fullPath) ? { redirect: to.fullPath } : null;
      next({ path: '/login', query });
    } else {
      next();
    }
  } else if(
    store.getters?.oktaIsAuthenticated
    && ['/login', '/signup'].find((item) => (
      to.fullPath.includes(item)
      // To stop a redirection loop - the below path will resolve as true when looking for '/login' in the path string
      && !to.fullPath.includes('/login/callback'))
      // They can access this page even if they're logged in
      && !to.fullPath.includes('/login/profile/email')
    )
  ) {
    next({ path: '/login/callback' });
  } else {
    next();
  }
});

export default router;
