/* istanbul ignore file */
export default {
  state: {
    user: {
      username: '',
      phone: '',
      setupMfa: false,
      mfaRequired: false,
      forgotPassword: false,
      newPassword: false,
      unlockAccount: false,
      sessionError: '',
      accountLocked: '',
      CIDI: {},
      CIDT: {},
      isGroupPlanEmployee: false,
      service: null,
      stateToken: '',
      emailToken: '',
      recoveryQuestion: '',
    },
  },
  getters: {
    user({ user }) {
      return user;
    },
    userUsername({ user: { username } }) {
      return username;
    },
    userPhone({ user: { phone } }) {
      return phone;
    },
    userSetupMfa({ user: { setupMfa } }) {
      return setupMfa;
    },
    userMfaRequired({ user: { mfaRequired } }) {
      return mfaRequired;
    },
    userForgotPassword({ user: { forgotPassword } }) {
      return forgotPassword;
    },
    userNewPassword({ user: { newPassword } }) {
      return newPassword;
    },
    userUnlockAccount({ user: { unlockAccount } }) {
      return unlockAccount;
    },
    userSessionError({ user: { sessionError } }) {
      return sessionError;
    },
    userAccountLocked({ user: { accountLocked } }) {
      return accountLocked;
    },
    userCidi({ user: { CIDI } }) {
      return CIDI;
    },
    userCidt({ user: { CIDT } }) {
      return CIDT;
    },
    userIsGroupPlanEmployee({ user: { isGroupPlanEmployee } }) {
      return isGroupPlanEmployee;
    },
    userCiAccounts({ user: { CIDT, CIDI, isGroupPlanEmployee } }) {
      return { CIDT, CIDI, isGroupPlanEmployee };
    },
    userService({ user: { service } }) {
      return service;
    },
    userStateToken({ user: { stateToken } }) {
      return stateToken;
    },
    userEmailToken({ user: { emailToken } }) {
      return emailToken;
    },
    userRecoveryQuestion({ user: { recoveryQuestion } }) {
      return recoveryQuestion;
    },
  },
  mutations: {
    updateUser(state, data) {
      Object.assign(state.user, data);
    },
    updateUserUsername(state, username) {
      state.user.username = username;
    },
    updateUserPhone(state, phone) {
      state.user.phone = phone;
    },
    updateUserSetupMfa(state, setupMfa) {
      state.user.setupMfa = setupMfa;
    },
    updateUserMfaRequired(state, mfaRequired) {
      state.user.mfaRequired = mfaRequired;
    },
    updateUserForgotPassword(state, forgotPassword) {
      state.user.forgotPassword = forgotPassword;
    },
    updateUserNewPassword(state, newPassword) {
      state.user.newPassword = newPassword;
    },
    updateUserUnlockAccount(state, unlockAccount) {
      state.user.unlockAccount = unlockAccount;
    },
    updateUserSessionError(state, sessionError) {
      state.user.sessionError = sessionError;
    },
    updateUserAccountLocked(state, accountLocked) {
      state.user.accountLocked = accountLocked;
    },
    updateUserCidi(state, CIDI) {
      state.user.CIDI = CIDI;
    },
    updateUserCidt(state, CIDT) {
      state.user.CIDT = CIDT;
    },
    updateUserIsGroupPlanEmployee(state, isGroupPlanEmployee) {
      state.user.isGroupPlanEmployee = isGroupPlanEmployee;
    },
    updateUserCiAccounts(state, { CIDI, CIDT, isGroupPlanEmployee }) {
      state.user.CIDI = CIDI;
      state.user.CIDT = CIDT;
      state.user.isGroupPlanEmployee = isGroupPlanEmployee || false;
    },
    updateUserService(state, service) {
      state.user.service = service;
    },
    updateUserStateToken(state, token) {
      state.user.stateToken = token;
    },
    updateUserEmailToken(state, token) {
      state.user.emailToken = token;
    },
    updateUserRecoveryQuestion(state, question) {
      state.user.recoveryQuestion = question;
    },
  },
  actions: {
    updateUser({ commit }, data) {
      commit('updateUser', data);
    },
    updateUserUsername({ commit }, data) {
      commit('updateUserUsername', data);
    },
    updateUserPhone({ commit }, data) {
      commit('updateUserPhone', data);
    },
    updateUserSetupMfa({ commit }, data) {
      commit('updateUserSetupMfa', data);
    },
    updateUserMfaRequired({ commit }, data) {
      commit('updateUserMfaRequired', data);
    },
    updateUserForgotPassword({ commit }, data) {
      commit('updateUserForgotPassword', data);
    },
    updateUserNewPassword({ commit }, data) {
      commit('updateUserNewPassword', data);
    },
    updateUserUnlockAccount({ commit }, data) {
      commit('updateUserUnlockAccount', data);
    },
    updateUserSessionError({ commit }, data) {
      commit('updateUserSessionError', data);
    },
    updateUserAccountLocked({ commit }, data) {
      commit('updateUserAccountLocked', data);
    },
    updateUserCidi({ commit }, data) {
      commit('updateUserCidi', data);
    },
    updateUserCidt({ commit }, data) {
      commit('updateUserCidt', data);
    },
    updateUserIsGroupPlanEmployee({ commit }, data) {
      commit('updateUserIsGroupPlanEmployee', data);
    },
    updateUserCiAccounts({ commit }, data) {
      commit('updateUserCiAccounts', data);
    },
    updateUserService({ commit }, data) {
      commit('updateUserService', data);
    },
    updateUserStateToken({ commit }, data) {
      commit('updateUserStateToken', data);
    },
    updateUserEmailToken({ commit }, data) {
      commit('updateUserEmailToken', data);
    },
    updateUserRecoveryQuestion({ commit }, data) {
      commit('updateUserRecoveryQuestion', data);
    },
  },
};
