/* istanbul ignore file */
export const OKTA_ISSUER = process.env.VUE_APP_OKTA_DOMAIN || '';
export const OKTA_CLIENT_ID = process.env.VUE_APP_OKTA_CLIENT_ID || '';
export const OKTA_REDIRECT_URI = process.env.VUE_APP_OKTA_REDIRECT_URI || '';
export const OKTA_SCOPES = ['openid', 'okta.users.read.self', 'okta.users.manage.self'];
export const OKTA_START_SERVICE = true;
export const DASHBOARD_API_URL = process.env.VUE_APP_DASHBOARD_API_URL || '';
export const OKTA_DEV_MODE = process.env.VUE_APP_OKTA_DEV_MODE === 'true';

export const OKTA_SETTINGS = {
  issuer: OKTA_ISSUER,
  clientId: OKTA_CLIENT_ID,
  redirectUri: OKTA_REDIRECT_URI,
  scopes: OKTA_SCOPES,
  devMode: OKTA_DEV_MODE,
};
