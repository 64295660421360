// Ctor to get the user details from localStorage if it's set
export function getLocalStorageUserCtor(win) {
  return () => {
    /* istanbul ignore next */
    const user = win.localStorage.getItem('okta-user');
    /* istanbul ignore next */
    return user ? JSON.parse(user) : null;
  }
}

// Store user details in localStorage
export function setLocalStorageUserCtor(win) {
  return (user) => win.localStorage.setItem('okta-user', JSON.stringify(user));
}

export const isiOS = [
  'iPad Simulator','iPhone Simulator','iPod Simulator','iPad','iPhone','iPod'
].includes(navigator.platform)
// iPad on iOS 13 detection
|| (navigator.userAgent.includes("Mac") && "ontouchend" in document);

/* istanbul ignore next */
export const getLocalStorageUser = getLocalStorageUserCtor(window);
/* istanbul ignore next */
export const setLocalStorageUser = setLocalStorageUserCtor(window);
/* istanbul ignore next */
export const isEmptyObject = (obj) => Object.getPrototypeOf(obj) !== Object.prototype || Object.entries(obj).length === 0 || Reflect.ownKeys(obj).length === 0;
