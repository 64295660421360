import Vue from 'vue';
import VueForm from 'vue-form';
import App from './App.vue';
import router from './router';
import store from './store';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faTwitter, faLinkedinIn, faFacebookF, faApple, faAndroid } from '@fortawesome/free-brands-svg-icons';
import { faCircleExclamation, faCircleCheck, faTriangleExclamation, faCaretDown, faEnvelope } from '@fortawesome/free-solid-svg-icons'
// Import everything needed for translation
import { registerLanguages, i18nMixin } from '@wealthbar/i18n';
import locale from './helpers/locale';

(async function bootstrap() {
  registerLanguages({
    'fr-CA': async () => {
      const lang = await import('../public/i18n/fr_CA.po');
      return lang.default;
    },
  });

  const language = locale.getLanguage();
  await locale.setLanguage(language);
  document.querySelector('html').setAttribute('lang', language.slice(0, 2));

  Vue.config.productionTip = process.env.NODE_ENV !== 'production';
  library.add(faCircleExclamation, faCircleCheck, faTriangleExclamation, faCaretDown, faTwitter, faLinkedinIn, faFacebookF, faEnvelope, faApple, faAndroid);
  Vue.component('FontAwesomeIcon', FontAwesomeIcon);
  Vue.use(VueForm);
  Vue.mixin(i18nMixin);

  new Vue({
    name: "CiInvesting",
    router,
    store,
    render: (h) => h(App),
  }).$mount('#app');
}());
