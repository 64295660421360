/* istanbul ignore file */
export default {
  state: {
    settings: {
      basic: {
        login: '',
        email: '',
        mobile_phone: '',
        first_name: '',
        last_name: '',
        locale: '',
      },
      questions: {
        available_questions: [],
        enrolled_recovery_question: '',
      },
      factors: [],
    },
  },
  getters: {
    settingsBasic({ settings: { basic } }) {
      return basic;
    },
    settingsLogin({ settings: { basic: { login } } }) {
      return login;
    },
    settingsEmail({ settings: { basic: { email } } }) {
      return email;
    },
    settingsPhone({ settings: { basic: { mobile_phone } } }) {
      return mobile_phone;
    },
    settingsFirstName({ settings: { basic: { first_name } } }) {
      return first_name;
    },
    settingsLastName({ settings: { basic: { last_name } } }) {
      return last_name;
    },
    settingsLocale({ settings: { basic: { locale } } }) {
      return locale;
    },
    settingsQuestions({ settings: { questions } }) {
      return questions;
    },
    settingsQuestionAvailable({ settings: { questions: { available_questions } } }) {
      return available_questions;
    },
    settingsQuestionEnrolled({ settings: { questions: { enrolled_recovery_question } } }) {
      return enrolled_recovery_question;
    },
    settingsFactors({ settings: { factors } }) {
      return factors;
    },
  },
  mutations: {
    updateSettingsBasic(state, data) {
      Object.assign(state.settings.basic, data);
    },
    updateSettingsLogin(state, login) {
      state.settings.basic.login = login;
    },
    updateSettingsEmail(state, email) {
      state.settings.basic.email = email;
    },
    updateSettingsPhone(state, mobile_phone) {
      state.settings.basic.mobile_phone = mobile_phone;
    },
    updateSettingsFirstName(state, first_name) {
      state.settings.basic.first_name = first_name;
    },
    updateSettingsLastName(state, last_name) {
      state.settings.basic.last_name = last_name;
    },
    updateSettingsLocale(state, locale) {
      state.settings.basic.locale = locale;
    },
    updateSettingsQuestions(state, questions) {
      state.settings.questions = questions;
    },
    updateSettingsQuestionAvailable(state, available_questions) {
      state.settings.questions.available_questions = available_questions;
    },
    updateSettingsQuestionEnrolled(state, enrolled_recovery_question) {
      state.settings.questions.enrolled_recovery_question = enrolled_recovery_question;
    },
    updateSettingsFactors(state, factors) {
      state.settings.factors = factors;
    },
  },
  actions: {
    updateSettingsBasic({ commit }, data) {
      commit('updateSettingsBasic', data);
    },
    updateSettingsLogin({ commit }, data) {
      commit('updateSettingsLogin', data);
    },
    updateSettingsEmail({ commit }, data) {
      commit('updateSettingsEmail', data);
    },
    updateSettingsPhone({ commit }, data) {
      commit('updateSettingsPhone', data);
    },
    updateSettingsFirstName({ commit }, data) {
      commit('updateSettingsFirstName', data);
    },
    updateSettingsLastName({ commit }, data) {
      commit('updateSettingsLastName', data);
    },
    updateSettingsLocale({ commit }, data) {
      commit('updateSettingsLocale', data);
    },
    updateSettingsQuestions({ commit }, data) {
      commit('updateSettingsQuestions', data);
    },
    updateSettingsQuestionAvailable({ commit }, data) {
      commit('updateSettingsQuestionAvailable', data);
    },
    updateSettingsQuestionEnrolled({ commit }, data) {
      commit('updateSettingsQuestionEnrolled', data);
    },
    updateSettingsFactors({ commit }, data) {
      commit('updateSettingsFactors', data);
    },
  },
};
