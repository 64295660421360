import store from '@/store';
import { getOktaAuth } from '@/lib/auth/oktaAuthInstance';

const _ = { getOktaAuth, store };

/* istanbul ignore next */
function authSubscription(authState) {
  // If they are no longer authenticated, take them to the homepage (should redirect to the login)
  if(!authState.isAuthenticated) {
    const baseUrl = (process?.env && process.env.baseUrl) ? process.env.baseUrl : '/';
    _.store.dispatch('updateOktaAuthenticated', false);
    window.location = baseUrl;
  }
}

// Function just sets oktaAuthenticated to true/false, even if there's an error.
// It's not meant to check for errors - just to set oktaAuthenticated to false is there is an error.
export async function setupOktaAuthenticatedCtor(_) {
  let oktaClient = _.store.getters.oktaAuthClient;
  // If the store doesn't have an oktaAuthClient, make one here and add it to the store
  if(!Object.keys(oktaClient).length) {
    try {
      oktaClient = await _.getOktaAuth();
      _.store.dispatch('updateOktaAuthClient', oktaClient);
    } catch {}
  }
  // Unsubscribe to the authentication event
  oktaClient.authStateManager.unsubscribe(authSubscription);
  // Check if the isAuthenticated method is available on the oktaAuthClient object
  if(oktaClient?.isAuthenticated) {
    try {
      const authenticated = await oktaClient.isAuthenticated()
      if(authenticated) {
        // If they're authenticated, get the access token
        try {
          const accessToken = await oktaClient.tokenManager.get('accessToken');
          // And resubscribe to the authentication event
          oktaClient.authStateManager.subscribe(authSubscription);
          _.store.dispatch('updateOktaAuthenticated', true);
          _.store.dispatch('updateOktaAccessToken', accessToken.accessToken);
        } catch {
          _.store.dispatch('updateOktaAuthenticated', false)
        }
        return authenticated;
      }
    } catch {
      _.store.dispatch('updateOktaAuthenticated', false);
      return;
    }
  }
  _.store.dispatch('updateOktaAuthenticated', false);
  return new Error('The user is not authenticated');
}

/* istanbul ignore next */
export const setupOktaAuthenticated = () => setupOktaAuthenticatedCtor(_);
